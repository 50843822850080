/* login.css */
main {
    background: linear-gradient(to bottom right, #011F43, #01426C);
}

.container-center {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.form-container {
    text-align: center;
}

button {
    background-color: #FFFFFF!important;
    color: #011F43!important;
    font-size: 20px!important;
    font-weight: 500!important;
    text-transform: uppercase!important;
    padding: 1rem 2rem!important;
    /* transition: 1s ease-in-out!important; */
}

button, button:hover, button:focus {
    box-shadow: none!important;
}

/* button:hover {
    border: 1px solid #FFFFFF!important;
    background: linear-gradient(to bottom right, #011F43, #01426C)!important;
    color: #FFFFFF!important;
} */

span, span a {
    color: #FFFFFF!important;
}

span a {
    font-weight: 500;
    text-decoration: none;
}
