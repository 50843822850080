@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css";

main {
    height: 100vh;
}

nav {
    width: 100%;
    height: 15vh;
    padding: .5rem 1rem;
    display: inline-flex;
    flex-direction: row;
    box-shadow: 10px 10px 10px #000000;
    justify-content: space-between;
}

nav img {
    height: 13vh;
    width: auto;
}

nav .right-nav {
    margin-top: auto;
    margin-bottom: auto;
}

nav .right-nav ul {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px;
    gap: 20px;
    color: #FFFFFF;
    list-style: none;
}

nav .right-nav ul li a {
    color: #FFFFFF;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 20px;
}

.toggle-password {
    float: right;
    cursor: pointer;
    margin-right: 10px;
    margin-top: -25px;
}

.scrollable-tbody {
    display: block;
    max-height: 600px; /* Set the desired height for the scrollable area */
    overflow-y: scroll; /* Enables vertical scrolling */
    width: 100%; /* Ensure the tbody takes the full width of the table */
}

.table {
    width: 100%; /* Ensure the table takes up the full width */
    box-shadow: 0px 0px 10px #000000; /* #f0f0f0 */
    border-radius: 10px;
}

thead, .scrollable-tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed; /* Ensure the width of each cell is consistent */
}

.scrollable-tbody td, .scrollable-tbody th {
    /* margin: 1rem; */
    white-space: nowrap; /* Prevent text from wrapping inside cells */
}

.btn {
    margin-right: 10px!important;
}

.key-table {
    margin-left: auto;
    margin-right: auto;
}
